<template>
  <div class="main">
    <div class="outer">
      <div class="item1">
        <div class="all-num">
          <div>{{ areaPeopleCount }}</div>
          <div>区域内人口总数</div>
        </div>
        <div class="older-num">
          <div style="color: #ff8529">{{ oldPeopleCount }}</div>
          <div>老龄人口总数</div>
        </div>
      </div>
      <div class="item2">
        <div id="oldrate"></div>
        <div class="desc">老龄化 {{ elderRatio }}%</div>
      </div>
      <div class="item3">
        <div id="oldtype"></div>
        <!-- <div class="desc">老龄化 24.5%</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { getStatistics } from '@/api/elderlyRecords/contract.js'
import echarts from 'echarts'
export default {
  data() {
    return {
      areaPeopleCount: 0, //街道人口总数
      oldPeopleCount: 0, //老龄人口口总数
      elderRatio: 0, //老龄化比列
      seniorPopulationCount: 0, //高龄老人数量
      seniorRatio: 0, //高龄老人比列
      singlePopulationCount: 0, //孤寡老人数量
      singleRatio: 0, //孤寡老人比列
      disablePopulationCount: 0, //残疾老人数量
      disableRatio: 0, //残疾老人数量
      poorPopulationCount: 0, //贫困老人数量
      poorRatio: 0, //贫困老人数量
    }
  },
  mounted() {
    this.getStatistics()
  },
  computed: {
    //获取用户信息
    userInfo() {
      return JSON.parse(sessionStorage.getItem('statistics-gov-userInfo'))
    },
  },
  methods: {
    getStatistics() {
      getStatistics(this.userInfo.areaCode).then((res) => {
        if (res.code == 0) {
          this.areaPeopleCount = res.data.olderInfo.streetPopulationCount
          this.oldPeopleCount = res.data.olderInfo.elderPopulationCount
          this.elderRatio = res.data.olderInfo.elderRatio
          this.seniorPopulationCount = res.data.olderInfo.seniorPopulationCount
          this.seniorRatio = res.data.olderInfo.seniorRatio
          this.singlePopulationCount = res.data.olderInfo.singlePopulationCount
          this.singleRatio = res.data.olderInfo.singleRatio
          this.disablePopulationCount =
            res.data.olderInfo.disablePopulationCount
          this.disableRatio = res.data.olderInfo.disableRatio
          this.poorPopulationCount = res.data.olderInfo.poorPopulationCount
          this.poorRatio = res.data.olderInfo.poorRatio
          this.initBaseInfo()
          this.initOlderType()
        }
      })
    },
    initBaseInfo() {
      var getmax = 100
      var getvalue = this.elderRatio
      var oldRate = echarts.init(document.getElementById('oldrate'))
      let option = {
        color: ['#FF6F0E', '#1F2A64'],
        tooltip: {
          show: false,
        },
        series: [
          {
            hoverAnimation: false,
            type: 'pie',
            radius: ['65%', '100%'],
            label: {
              show: true,
              position: 'center',
              formatter: '老龄化',
              color: '#69B1FF',
              fontWeight: 'bold',
              //   fontSize: '0.58rem',
            },
            data: [
              { value: getvalue, name: '老龄化' },
              { value: 100 - getvalue, name: '' },
            ],
          },
        ],
      }
      oldRate.setOption(option)
      window.addEventListener('resize', function() {
        oldRate.resize()
      })
    },
    initOlderType() {
      var chart = echarts.init(document.getElementById('oldtype'))
      let option = {
        color: [
          '#ff1b55',
          '#fffe02',
          '#61feff',
          '#6861ff',
          '#ff9f1b',
          '#30f279',
          '#ff35f0',
          '#359dff',
        ],
        legend: {
          bottom: '-3%',
          itemWidth: 13,
          itemHeight: 8,
          textStyle: {
            color: '#8EB8E6',
          },
        },
        grid: {
          bottom: '20%',
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [20, 45],
            center: ['50%', '37%'],
            roseType: 'area',
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              formatter: '{c}人\n{d}%',
            },
            data: [
              { name: '高龄老人', value: this.seniorPopulationCount },
              {
                name: '残障(失能失智)老人',
                value: this.disablePopulationCount,
              },
              { name: '贫困老人', value: this.poorPopulationCount },
              { name: '孤寡老人', value: this.singlePopulationCount },
            ],
          },
        ],
      }
      chart.setOption(option)
      window.addEventListener('resize', function() {
        chart.resize()
      })
    },
  },
}
</script>

<style scoped>
.main {
  background: url('~@/assets/images/twoThreeImgs/left/baseInfoBg.png');
  width: 586px;
  height: 232px;
  overflow: hidden;
  /* padding-top: 1px; */
}
.outer {
  margin-top: 44px;
  height: 186px;
  width: 100%;
  padding: 0 10px 10px;
  /* border: 1px solid red; */
  display: grid;
  grid-template-columns: 128px 140px 278px;
  gap: 10px;
  /* grid-template-rows: 100px 100px 100px; */
}
.item1,
.item2,
.item3 {
  width: 128px;
  height: 176px;
}
.item2 {
  width: 140px;
}
.item3 {
  width: 278px;
}
.item1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.item1 .all-num {
  width: 128px;
  height: 83px;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item1 .all-num :first-child,
.item1 .older-num :first-child {
  margin-top: 9px;
  font-size: 30px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #b4d3fe;
  line-height: 35px;
}
.item1 .all-num :last-child,
.item1 .older-num:last-child {
  margin-top: 4px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 16px;
}
.item1 .older-num {
  width: 128px;
  height: 83px;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.item2,
.item3 {
  height: 174px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid red; */
  border: 1px solid #213060;
}
.item2 #oldrate {
  width: 100px;
  height: 100px;
  margin-top: 16px;
  margin-bottom: 8px;
}
.item3 #oldtype {
  width: 100%;
  height: 100%;
  margin-top: 16px;
  margin-bottom: 8px;
}
.item2 .desc {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
  line-height: 16px;
}
</style>
